<template>
 <v-container>
   <v-row>
     <v-col cols="12">
<!--       <v-img max-height="500" :src="require(`@/assets/img/${active_blog.image}`)"></v-img>-->
       <v-img max-height="500" :src="'https://protomic.hr/admin/api/storage/' + this.active_blog.images[0]"></v-img>
     </v-col>
   </v-row>
   <v-row align="center" justify="center">
     <v-col cols="12" md="8">
       <div class="text-h4 primary--text">
         {{active_blog.title}}
       </div>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="2" class="hidden-xs-only"></v-col>
     <v-col cols="1">
       <v-icon>mdi-calendar</v-icon>
     </v-col>
     <v-col cols="3">{{active_blog.created_at}}</v-col>
   </v-row>

   <v-row align="center" justify="center">
     <v-col cols="12" md="8" v-html="active_blog.content"></v-col>
   </v-row>
   <v-row>
     <v-col cols="2" class="hidden-xs-only"></v-col>
     <v-col cols="1">
       <v-icon>mdi-account</v-icon>
     </v-col>
     <v-col cols="3">Ivan Tomić</v-col>
   </v-row>
 </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "BlogPost",
  created() {
    this.alias = this.$route.params.alias
    // this.blogs.forEach(el => {
    //   if(el.alias === this.$route.params.alias) {
    //     this.active_blog = el
    //   }
    // })
    this.getBlog()
  },
  methods: {
    getBlog() {
      let requestBody = {
        alias: this.alias
      };

      axios
          .post("https://protomic.hr/admin/api/web/objava", requestBody)
          .then(response => {
            this.active_blog = response.data;
            console.log(this.active_blog)
            // for (let i = 0; i < this.active_blog.images.length; i++) {
            //   this.lista_slika.push(
            //       "https://protomic.hr/admin/api/storage/" + this.active_blog.images[i]
            //   );
            // }
          })
          .catch(error => console.log(error.message));
    },
  },
  data: () => ({
    active_blog: undefined,
    alias: '',
    lista_slika: [],
    blogs: []
  })
}
</script>

<style scoped>

</style>